//
//  BuilderLandingPage.js
//  
//
//  Created by Nathan Cumberbatch on 12/4/24.
//

// BuilderConfig.js
import { builder } from '@builder.io/react';

// Initialize Builder.io with your Public API Key
builder.init("1f97434d8d9947fd810921fcdcd90f98"); // Replace with your actual API key
