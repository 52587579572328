import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { BuilderComponent } from "@builder.io/react";
import "./BuilderConfig"; // Import your Builder.io configuration
import "./BuilderRegister"; // Import your registered Builder components
import ReferalPage from "./screens/ReferalPage";
import LandingPage from "./screens/LandingPage";

function App() {
  return (
    <Router>
      <Routes>
        {/* Render Builder.io content for the Landing Page */}
        <Route path="/:uniqueId?" element={<LandingPage />} />

        {/* Existing Referral Page */}
        <Route path="/ReferalPage" element={<ReferalPage />} />
      </Routes>
    </Router>
  );
}

export default App;
