/** @format */

// /** @format */

// import React, { useRef, useState } from "react";
// import { Box, Typography, Grid, TextField, Button } from "@mui/material";
// import logoo from "../images/logo.png";
// import homebg from "../images/homePage.png";
// import { useLocation } from "react-router-dom";
// import ToastMessagee from "../components/ToastMessage";

// const ReferalPage = () => {
//   const location = useLocation();
//   const { email, uniqueId, userPoints } = location.state || {};
//   console.log("state", email, uniqueId, userPoints);
//   const inputRef = useRef(null);
//   const [toastVisible, setToastVisible] = useState(false);
//   const [toastMessage, setToastMessage] = useState("");
//   const [toastType, setToastType] = useState("success");

//   const copyToClipboard = () => {
//     navigator.clipboard.writeText(`https://drinkbathwater.com/${uniqueId}`);

//     setToastMessage("Link copied to clipboard!");
//     setToastType("success");
//     setToastVisible(true);
//     setTimeout(() => {
//       setToastVisible(false);
//     }, 3000);
//   };

//   return (
//     <Box
//       sx={{
//         position: "absolute",
//         width: "100%",
//         height: "100%",
//         backgroundImage: `url(${homebg})`,
//         backgroundSize: "cover",
//         backgroundPosition: "center",
//         backgroundAttachment: "fixed",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         overflow: "hidden", //.....
//       }}
//     >
//       {toastVisible && (
//         <ToastMessagee
//           message={toastMessage}
//           type={toastType}
//           onClose={() => setToastVisible(false)}
//         />
//       )}
//       <Box
//         sx={{
//           width: "100%",
//           position: "relative",
//           overflow: "auto",
//           display: "flex",
//           flexDirection: "column",
//           justifyContent: "flex-start",
//           alignItems: "center",
//           height: "100vh",
//           paddingTop: { xs: "20px", sm: "40px", md: "60px" },
//           paddingBottom: { xs: "20px", sm: "40px", md: "60px" },
//         }}
//       >
//         {/* Logo centered at the top */}
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             width: "100%",
//             my: { xs: 5, sm: 3, md: 4 },
//           }}
//         >
//           <img
//             src={logoo}
//             alt="Logo"
//             style={{
//               width: "15%",
//               height: "auto",
//             }}
//           />
//         </Box>
//         <Grid
//           container
//           spacing={2}
//           sx={{
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Grid item xs={0} md={1} />
//           <Grid
//             item
//             xs={10}
//             md={10}
//             sx={{
//               height: "auto",
//               display: "flex",
//               flexDirection: "column",
//               justifyContent: "center",
//               // px: { xs: 3, sm: 4, md: 5 },
//             }}
//           >
//             <Typography
//               variant="h5"
//               color="#00AEEF"
//               fontFamily="Awesome Serif"
//               sx={{
//                 fontSize: {
//                   xs: "1.05rem",
//                   sm: "1.5rem",
//                   md: "1.75rem",
//                   lg: "2rem",
//                 },
//                 textAlign: { xs: "center", sm: "center", md: "center" },
//                 lineHeight: 1.4,
//               }}
//             >
//               Launching 12/2024 at 9AM PST
//             </Typography>
//           </Grid>

//           <Grid item xs={2} md={1} />
//         </Grid>
//         <Grid
//           container
//           spacing={2}
//           sx={{
//             justifyContent: "center",
//             alignItems: "center",
//             width: "100%",
//           }}
//         >
//           <Grid item xs={2} md={1} />
//           <Grid
//             item
//             xs={10}
//             md={10}
//             sx={{
//               height: "auto",
//               display: "flex",
//               flexDirection: "column",
//               justifyContent: "center",
//               px: { xs: 3, sm: 4, md: 5 },
//             }}
//           >
//             {/* <Box
//               sx={{
//                 backgroundColor: '#BFD730',
//                 width: { xs: '80%', sm: '70%', md: '60%' },
//                 borderRadius: 10,
//                 py: 2,
//                 my: { xs: 1, sm: 1, md: 3 },
//                 textAlign: 'center',
//                 boxSizing: 'border-box',
//               }}>
//               <Typography
//                 variant='h4'
//                 sx={{
//                   color: '#00215B',
//                   fontFamily: 'Awesome Serif',
//                   fontWeight: 600,
//                   transform: 'scaleY(1.3)',
//                   transformOrigin: 'center',
//                   letterSpacing: -1,
//                   fontSize: {
//                     xs: '0.9rem',
//                     sm: '0.9rem',
//                     md: '1.2rem',
//                     transform: 'scale(1.6)',
//                     transformOrigin: 'center',
//                   },
//                   lineHeight: 1.4,
//                 }}>
//                 WELCOME TO THE BATHWATER
//               </Typography>
//             </Box> */}
//             <Box
//               sx={{
//                 backgroundColor: "#BFD730",
//                 width: { xs: "90%", sm: "80%", md: "70%" }, // Adjust width on different screens
//                 borderRadius: 10,
//                 py: { xs: 1, sm: 2, md: 3 }, // Adjust padding based on screen size
//                 my: { xs: 1, sm: 2, md: 3 }, // Adjust margin for spacing on different screen sizes
//                 textAlign: "center",
//                 boxSizing: "border-box",
//               }}
//             >
//               <Typography
//                 variant="h4"
//                 sx={{
//                   color: "#00215B",
//                   fontFamily: "Awesome Serif",
//                   fontWeight: 600,
//                   transform: "scaleY(1.3)",
//                   transformOrigin: "center",
//                   // letterSpacing: -1,
//                   fontSize: {
//                     xs: "1rem", // For small screens (mobile)
//                     sm: "1.2rem", // For medium screens (tablets)
//                     md: "2rem", // For larger screens (desktops)
//                   },
//                 }}
//               >
//                 WELCOME TO THE BATHWATER
//               </Typography>
//             </Box>
//           </Grid>

//           <Grid item xs={2} md={1} />
//         </Grid>
//         <Grid
//           container
//           spacing={2}
//           sx={{
//             justifyContent: "center",
//             alignItems: "center",
//             width: "100%",
//             my: { xs: 0, sm: 0.7, md: 1 },
//           }}
//         >
//           <Grid item xs={2} md={1} />
//           <Grid
//             item
//             xs={10}
//             md={10}
//             sx={{
//               height: "auto",
//               display: "flex",
//               flexDirection: "column",
//               justifyContent: "center",
//               px: { xs: 3, sm: 5, md: 7 },
//               my: { xs: 1, sm: 1, md: 2, lg: 2 },
//             }}
//           >
//             <Typography
//               variant="h1"
//               sx={{
//                 color: "#fff",
//                 fontWeight: "800",
//                 transform: "scaleY(2)",
//                 transformOrigin: "center",
//                 fontSize: { xs: "2.5rem", sm: "3rem", md: "5rem" },
//                 textAlign: { xs: "left", sm: "left", md: "left" },
//                 width: "100%",
//                 lineHeight: 1,
//                 my: { xs: 2, sm: 2 },
//               }}
//             >
//               SPREAD THE WORLD
//             </Typography>
//           </Grid>

//           <Grid item xs={2} md={1} />
//         </Grid>
//         <Grid
//           container
//           spacing={2}
//           sx={{
//             justifyContent: "center",
//             alignItems: "center",
//             width: "100%",
//             my: 1,
//           }}
//         >
//           <Grid item xs={2} md={1} />
//           <Grid
//             item
//             xs={10}
//             md={10}
//             sx={{
//               height: "auto",
//               display: "flex",
//               flexDirection: "column",
//               justifyContent: "center",
//               px: { xs: 2, sm: 3, md: 5 },
//             }}
//           >
//             <Typography
//               variant="body1"
//               sx={{
//                 color: "#fff",
//                 width: { xs: "100%", sm: "80%", md: "75%" },
//                 fontFamily: "Serif",
//                 fontSize: {
//                   xs: "1.02rem",
//                   sm: "1.25rem",
//                   md: "1.3rem",
//                   lg: "1.5rem",
//                 },
//                 lineHeight: 1.5,
//                 textAlign: { xs: "left", sm: "left" },
//               }}
//             >
//               We created bath water to power your potential. Our initial run is
//               a small batch of potent magnesium-powered hydration. Get first
//               access and earn rewards by sharing this link with your friends.
//             </Typography>
//           </Grid>

//           <Grid item xs={2} md={1} />
//         </Grid>

//         <Grid
//           container
//           spacing={2}
//           sx={{
//             justifyContent: "center",
//             alignItems: "center",
//             width: "100%",
//             my: 1,
//           }}
//         >
//           <Grid item xs={2} md={1} />
//           <Grid
//             item
//             xs={10}
//             md={10}
//             sx={{
//               height: "auto",
//               display: "flex",
//               flexDirection: "column",
//               justifyContent: "center",
//               px: { xs: 2, sm: 3, md: 5 },
//             }}
//           >
//             <Typography
//               sx={{
//                 color: "#BFD730",
//                 width: { xs: "100%", sm: "80%", md: "70%" },
//                 fontFamily: "Serif",
//                 fontSize: {
//                   xs: "1.1rem",
//                   sm: "1.4rem",
//                   md: "1.5rem",
//                   lg: "2.2rem",
//                 },
//                 lineHeight: 1.5,
//                 textAlign: { xs: "left", sm: "left" },
//               }}
//             >
//               Share the referral link
//             </Typography>
//             <Typography
//               variant="body1"
//               sx={{
//                 color: "#fff",
//                 width: { xs: "100%", sm: "80%", md: "70%", lg: "55%" },
//                 fontFamily: "Serif",
//                 fontSize: {
//                   xs: "1.02rem",
//                   sm: "1.25rem",
//                   md: "1.3rem",
//                   lg: "1.5rem",
//                 },
//                 lineHeight: 1.5,
//                 textAlign: { xs: "left", sm: "left" },
//               }}
//             >
//               You can also share your referral link by copying and sending it or
//               sharing it on your social media.
//             </Typography>
//           </Grid>

//           <Grid item xs={2} md={1} />
//         </Grid>
//         <Grid
//           container
//           spacing={2}
//           sx={{
//             justifyContent: "center",
//             alignItems: "center",
//             width: "100%",
//             my: 1,
//           }}
//         >
//           <Grid item xs={1} md={1} />
//           <Grid
//             item
//             xs={12}
//             md={10}
//             sx={{
//               height: "auto",
//               display: "flex",
//               flexDirection: "row",
//               justifyContent: "center",
//               alignItems: "center",
//               px: { xs: 2, sm: 3, md: 5 },
//             }}
//           >
//             <Box
//               sx={{
//                 position: "relative",
//                 display: "flex",
//                 flexDirection: { xs: "column", sm: "row" },
//                 justifyContent: "space-between",
//                 alignItems: "center",
//                 width: "100%",
//                 border: "1px solid white",
//                 borderRadius: 2,
//                 px: 1,
//                 py: { xs: 1 },
//               }}
//             >
//               <TextField
//                 placeholder="Enter your email"
//                 value={`https://drinkbathwater.com/${uniqueId}`}
//                 inputRef={inputRef}
//                 sx={{
//                   backgroundColor: "none",
//                   width: "100%",
//                   "& .MuiOutlinedInput-root": {
//                     "& fieldset": {
//                       border: "none",
//                     },
//                     "&:hover fieldset": {
//                       border: "none",
//                     },
//                     "&.Mui-focused fieldset": {
//                       border: "none",
//                     },
//                   },
//                   "& .MuiInputBase-input": {
//                     color: "white",
//                   },
//                   "& .MuiInputBase-input::placeholder": {
//                     color: "white",
//                   },
//                 }}
//               />

//               <Button
//                 onClick={copyToClipboard}
//                 sx={{
//                   backgroundColor: "#BFD730",
//                   color: "#000",
//                   borderRadius: "10px",
//                   px: { xs: 4, sm: 5, md: 7 },
//                   py: { xs: 1, sm: 1, md: 2 },
//                   fontSize: { xs: "0.875rem", sm: "1rem", md: "1rem" },
//                   fontFamily: "serif",
//                   minWidth: { xs: "auto", sm: "200px", md: "280px" },
//                   width: { xs: "100%", sm: "auto" },
//                   "&:hover": {
//                     backgroundColor: "#A0C200",
//                   },
//                 }}
//               >
//                 Copy Link
//               </Button>
//             </Box>
//             {/* <Box
//               sx={{
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//               }}>
//               <Box sx={{ mx: 2 }}>
//                 <FacebookOutlinedIcon
//                   style={{ height: 50, width: 50, color: '#A0C200' }}
//                 />
//               </Box>
//               <Box sx={{ mx: 2 }}>
//                 <XIcon style={{ height: 50, width: 50, color: '#A0C200' }} />
//               </Box>
//               <Box sx={{ mx: 2 }}>
//                 <InstagramIcon
//                   style={{ height: 50, width: 50, color: '#A0C200' }}
//                 />
//               </Box>
//             </Box> */}
//           </Grid>

//           <Grid item xs={1} md={1} />
//         </Grid>

//         {/* --------------------------------------------------------------------------- */}
//         <Box backgroundColor={"#f0f0f0"} width={"100%"}>
//           <Box
//             sx={{
//               zIndex: 1,
//               padding: 2,
//               display: "flex",
//               flexDirection: "column",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           >
//             <Typography
//               variant="h4"
//               fontFamily={"Awesome serif"}
//               color="red"
//               sx={{
//                 mt: 6,
//                 mb: 2,
//                 fontSize: {
//                   xs: "1.2rem",
//                   sm: "1.5rem",
//                   md: "1.75rem",
//                   lg: "2rem",
//                 },
//               }}
//             >
//               You have 5 days left
//             </Typography>
//             <Typography
//               variant="h1"
//               sx={{
//                 fontFamily: "Awesome serif",
//                 fontWeight: 800,
//                 // letterSpacing: -3,
//                 fontSize: { xs: "2.5rem", sm: "3rem", md: "4rem", lg: "5rem" },
//               }}
//             >
//               {userPoints} Friends joined
//             </Typography>
//             <Typography
//               variant="h6"
//               fontFamily={"Awesome serif"}
//               textAlign={"center"}
//               sx={{
//                 fontSize: {
//                   xs: "1rem",
//                   sm: "1.25rem",
//                   md: "1.5rem",
//                   lg: "1.75rem",
//                 },
//               }}
//             >
//               1 More For Next Reward: Free Sample Of All Flavours
//             </Typography>
//           </Box>

//           <Grid
//             container
//             sx={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           >
//             <Grid
//               xs={10}
//               sm={10}
//               md={10}
//               lg={10}
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "center",
//               }}
//             >
//               <Box
//                 sx={{
//                   py: { xs: 1, sm: 1, md: 3, lg: 3 },
//                   px: { xs: 2, sm: 2, md: 3, lg: 3 },
//                   backgroundColor: "#FFA500",
//                   borderRadius: 3,
//                   display: "flex",
//                   flexDirection: "row",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   my: { xs: 1, sm: 1, md: 1, lg: 1 },
//                 }}
//               >
//                 <Box
//                   sx={{
//                     backgroundColor: "#fff",
//                     borderRadius: 3,
//                     py: { xs: 1 },
//                     px: { xs: 2 },
//                   }}
//                 >
//                   <Typography
//                     variant="h4"
//                     sx={{
//                       color: "#000",
//                       fontFamily: "Awesome serif",
//                       fontWeight: 700,
//                       fontSize: {
//                         xs: "1rem",
//                         sm: "1.25rem",
//                         md: "1.5rem",
//                         lg: "1.75rem",
//                       },
//                     }}
//                   >
//                     #1 Referrer
//                   </Typography>
//                 </Box>
//                 <Typography
//                   variant="h4"
//                   sx={{
//                     fontFamily: "Awesome serif",
//                     fontWeight: 700,
//                     fontSize: {
//                       xs: "1rem",
//                       sm: "1.25rem",
//                       md: "1.5rem",
//                       lg: "1.75rem",
//                     },
//                   }}
//                 >
//                   2024 Olympics Trip
//                 </Typography>
//               </Box>

//               <Box
//                 sx={{
//                   py: { xs: 1, sm: 1, md: 3, lg: 3 },
//                   px: { xs: 2, sm: 2, md: 3, lg: 3 },
//                   backgroundColor: "#FFA500",
//                   borderRadius: 3,
//                   display: "flex",
//                   flexDirection: "row",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   my: { xs: 1, sm: 1, md: 1, lg: 1 },
//                 }}
//               >
//                 <Box
//                   sx={{
//                     display: "flex",
//                     flexDirection: "row",
//                     alignItems: "center",
//                   }}
//                 >
//                   <Box
//                     sx={{
//                       backgroundColor: "#fff",
//                       borderRadius: 3,
//                       py: { xs: 1 },
//                       px: { xs: 2 },
//                     }}
//                   >
//                     <Typography
//                       variant="h4"
//                       sx={{
//                         color: "#000",
//                         fontFamily: "Awesome serif",
//                         fontWeight: 700,
//                         fontSize: {
//                           xs: "1rem",
//                           sm: "1.25rem",
//                           md: "1.5rem",
//                           lg: "1.75rem",
//                         },
//                       }}
//                     >
//                       Top Ten Referrer
//                     </Typography>
//                   </Box>
//                   <Typography
//                     variant="h4"
//                     sx={{
//                       color: "#000",
//                       fontFamily: "Awesome serif",
//                       mx: { xs: 1, sm: 1, md: 1, lg: 1 },
//                       fontSize: {
//                         xs: "1rem",
//                         sm: "1.25rem",
//                         md: "1.5rem",
//                         lg: "1.75rem",
//                       },
//                     }}
//                   >
//                     (20 Friends)
//                   </Typography>
//                 </Box>
//                 <Typography
//                   variant="h4"
//                   sx={{
//                     fontFamily: "Awesome serif",
//                     fontWeight: 700,
//                     fontSize: {
//                       xs: "1rem",
//                       sm: "1.25rem",
//                       md: "1.5rem",
//                       lg: "1.75rem",
//                     },
//                   }}
//                 >
//                   One Year Of Free Bath Water
//                 </Typography>
//               </Box>

//               <Box
//                 sx={{
//                   py: { xs: 1, sm: 1, md: 3, lg: 3 },
//                   px: { xs: 2, sm: 2, md: 3, lg: 3 },
//                   backgroundColor: "#FFA500",
//                   borderRadius: 3,
//                   display: "flex",
//                   flexDirection: "row",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   my: { xs: 1, sm: 1, md: 1, lg: 1 },
//                 }}
//               >
//                 <Box
//                   sx={{
//                     display: "flex",
//                     flexDirection: "row",
//                     alignItems: "center",
//                   }}
//                 >
//                   <Box
//                     sx={{
//                       height: 15,
//                       width: 15,
//                       border: userPoints == 0 && "2px solid #ED3B24",
//                       backgroundColor: userPoints >= 1 && "#ED3B24",
//                       borderRadius: 5,
//                     }}
//                   />
//                   <Typography
//                     variant="h4"
//                     sx={{
//                       color: "#000",
//                       fontFamily: "Awesome serif",
//                       mx: { xs: 1, sm: 1, md: 2, lg: 2 },
//                       fontSize: {
//                         xs: "1rem",
//                         sm: "1.25rem",
//                         md: "1.5rem",
//                         lg: "1.75rem",
//                       },
//                     }}
//                   >
//                     1 Friend
//                   </Typography>
//                 </Box>
//                 <Typography
//                   variant="h4"
//                   sx={{
//                     fontFamily: "Awesome serif",
//                     fontWeight: 700,
//                     fontSize: {
//                       xs: "1rem",
//                       sm: "1.25rem",
//                       md: "1.5rem",
//                       lg: "1.75rem",
//                     },
//                   }}
//                 >
//                   Free Sample Of All Flavours
//                 </Typography>
//               </Box>

//               <Box
//                 sx={{
//                   py: { xs: 1, sm: 1, md: 3, lg: 3 },
//                   px: { xs: 2, sm: 2, md: 3, lg: 3 },
//                   backgroundColor: "#FFA500",
//                   borderRadius: 3,
//                   display: "flex",
//                   flexDirection: "row",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   my: { xs: 1, sm: 1, md: 1, lg: 1 },
//                 }}
//               >
//                 <Box
//                   sx={{
//                     display: "flex",
//                     flexDirection: "row",
//                     alignItems: "center",
//                   }}
//                 >
//                   <Box
//                     sx={{
//                       height: 15,
//                       width: 15,
//                       border: userPoints < 5 && "2px solid #ED3B24",
//                       backgroundColor: userPoints >= 5 && "#ED3B24",
//                       borderRadius: 5,
//                     }}
//                   />
//                   <Typography
//                     variant="h4"
//                     sx={{
//                       color: "#000",
//                       fontFamily: "Awesome serif",
//                       mx: { xs: 1, sm: 1, md: 2, lg: 2 },
//                       fontSize: {
//                         xs: "1rem",
//                         sm: "1.25rem",
//                         md: "1.5rem",
//                         lg: "1.75rem",
//                       },
//                     }}
//                   >
//                     5 Friends
//                   </Typography>
//                 </Box>
//                 <Typography
//                   variant="h4"
//                   sx={{
//                     fontFamily: "Awesome serif",
//                     fontWeight: 700,
//                     fontSize: {
//                       xs: "1rem",
//                       sm: "1.25rem",
//                       md: "1.5rem",
//                       lg: "1.75rem",
//                     },
//                   }}
//                 >
//                   Bath Water Branded Merch
//                 </Typography>
//               </Box>
//             </Grid>
//           </Grid>

//           <Grid
//             container
//             sx={{
//               backgroundColor: "#00AEEF",
//               py: 4,
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               mt: 6,
//             }}
//           >
//             <Typography
//               variant="h5"
//               color={"#fff"}
//               fontFamily={"Awesome serif"}
//               sx={{
//                 fontSize: {
//                   xs: 15,
//                   sm: 18,
//                   md: 20,
//                   lg: 20,
//                 },
//               }}
//             >
//               Need Help? Contact us at : info@bathwater.com
//             </Typography>
//           </Grid>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default ReferalPage;

import React, { useRef, useState } from 'react';
import { Box, Typography, Grid, TextField, Button } from '@mui/material';
import logoo from '../images/logo.png';
import homebg from '../images/BGImageMob.png';
import { useLocation } from 'react-router-dom';
import ToastMessagee from '../components/ToastMessage';
import CountDown from '../components/CountDown';
import Navbar from '../components/Navbar';

const ReferalPage = () => {
  const location = useLocation();
  const { email, uniqueId, userPoints } = location.state || {};
  console.log('state', email, uniqueId, userPoints);
  const inputRef = useRef(null);
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');

  const targetDate = '2025-02-01T00:00:00';
  const { days, hours, minutes, seconds } = CountDown(targetDate);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`https://drinkbathwater.com/${uniqueId}`);

    setToastMessage('Link copied to clipboard!');
    setToastType('success');
    setToastVisible(true);
    setTimeout(() => {
      setToastVisible(false);
    }, 3000);
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundImage: `url(${homebg})`,
        backgroundSize: {
          xs: 'contain',
          sm: 'contain',
          md: 'cover',
          lg: 'cover',
        },
        backgroundRepeat: 'no-repeat',
        // backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden', //.....
      }}>
      {toastVisible && (
        <ToastMessagee
          message={toastMessage}
          type={toastType}
          onClose={() => setToastVisible(false)}
        />
      )}
      <Box
        sx={{
          width: '100%',
          position: 'relative',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',

          alignItems: 'center',
          height: '100vh',
          paddingTop: { xs: '20px', sm: '40px', md: '60px' },
          paddingBottom: { xs: '20px', sm: '40px', md: '60px' },
        }}>
        {/* Logo centered at the top */}
        {/* <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            py: { xs: 3, sm: 2, md: 3 },
            backgroundColor: '#fff',
          }}>
          <img
            src={logoo}
            alt='Logo'
            style={{
              width: '15%',
              height: 'auto',
            }}
          />
        </Box> */}
        <Navbar
          emailAddress={email}
          rank={'Rank: 114'}
        />
        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Grid
            item
            xs={0}
            md={1}
          />
          <Grid
            item
            xs={12}
            md={10}
            sx={{
              height: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              // alignItems: 'center',
              py: { xs: 0, sm: 2, md: 5 },
              mt: { xs: 2, sm: 2, md: 5 },
              px: { xs: 3, sm: 4, md: 5 },
            }}>
            <Typography
              variant='h5'
              color='#fff'
              fontFamily='Etrusco'
              fontWeight={'bold'}
              sx={{
                fontSize: {
                  xs: '1.05rem',
                  sm: '1.5rem',
                  md: '1.75rem',
                  lg: '2rem',
                },
                ml: { xs: 2, sm: 0, md: 0 },
                textAlign: { xs: 'left', sm: 'center', md: 'center' },
                lineHeight: 1,
              }}>
              Launching Feb 1, 2025 at 9AM PST
            </Typography>
          </Grid>

          <Grid
            item
            xs={0}
            md={1}
          />
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Grid
            item
            xs={0}
            md={1}
          />
          <Grid
            item
            xs={12}
            md={10}
            sx={{
              height: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              px: { xs: 3, sm: 4, md: 5 },
            }}>
            <Typography
              variant='h4'
              sx={{
                color: '#00215B',
                fontFamily: 'Etrusco',
                fontWeight: 600,
                transform: 'scaleY(1.3)',
                transformOrigin: 'center',
                textAlign: { xs: 'left', sm: 'center', md: 'center' },
                letterSpacing: 1,
                ml: { xs: 2, sm: 0, md: 0 },
                fontSize: {
                  xs: '1rem', // For small screens (mobile)
                  sm: '1rem', // For medium screens (tablets)
                  md: '2.5rem', // For larger screens (desktops)
                },
                lineHeight: 1.0,
              }}>
              WELCOME TO THE BATHWATER
            </Typography>
            {/* <Box
              sx={{
                backgroundColor: '#BFD730',
                width: { xs: '80%', sm: '70%', md: '60%' },
                borderRadius: 10,
                py: 2,
                my: { xs: 1, sm: 1, md: 3 },
                textAlign: 'center',
                boxSizing: 'border-box',
              }}>
              <Typography
                variant='h4'
                sx={{
                  color: '#00215B',
                  fontFamily: 'Awesome Serif',
                  fontWeight: 600,
                  transform: 'scaleY(1.3)',
                  transformOrigin: 'center',
                  letterSpacing: -1,
                  fontSize: {
                    xs: '0.9rem',
                    sm: '0.9rem',
                    md: '1.2rem',
                    transform: 'scale(1.6)',
                    transformOrigin: 'center',
                  },
                  lineHeight: 1.4,
                }}>
                WELCOME TO THE BATHWATER
              </Typography>
            </Box> */}
            {/* <Box
              sx={{
                backgroundColor: '#BFD730',
                width: { xs: '90%', sm: '80%', md: '70%' }, // Adjust width on different screens
                borderRadius: 10,
                py: { xs: 1, sm: 2, md: 3 }, // Adjust padding based on screen size
                my: { xs: 1, sm: 2, md: 3 }, // Adjust margin for spacing on different screen sizes
                textAlign: 'center',
                boxSizing: 'border-box',
                mx: 'auto', // Center the box horizontally
              }}></Box> */}
          </Grid>

          <Grid
            item
            xs={0}
            md={1}
          />
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            my: { xs: 0, sm: 0.7, md: 1 },
          }}>
          <Grid
            item
            xs={0}
            md={1}
          />
          <Grid
            item
            xs={12}
            md={10}
            sx={{
              height: 'auto',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              px: { xs: 0, sm: 5, md: 7 },
              my: { xs: 0, sm: 1, md: 2, lg: 2 },
            }}>
            <Typography
              variant='h1'
              sx={{
                color: '#fff',
                fontWeight: '600',
                transform: 'scaleY(2)',
                transformOrigin: 'center',
                fontSize: { xs: '2.5rem', sm: '2rem', md: '3rem' },
                textAlign: { xs: 'left', sm: 'center', md: 'center' },
                width: '100%',
                // lineHeight: 1,
                letterSpacing: 1,
                fontFamily: 'Etrusco',
                // my: { xs: 2, sm: 2 },
              }}>
              SPREAD THE WORLD
            </Typography>
          </Grid>

          <Grid
            item
            xs={0}
            md={1}
          />
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            // my: 1,
          }}>
          <Grid
            item
            xs={0}
            md={1}
          />
          <Grid
            item
            xs={12}
            md={10}
            sx={{
              height: 'auto',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              px: { xs: 0, sm: 3, md: 5 },
            }}>
            <Typography
              variant='body1'
              sx={{
                color: '#fff',
                width: { xs: '100%', sm: '80%', md: '75%' },
                fontFamily: 'Etrusco',
                fontSize: {
                  xs: '1.5rem',
                  sm: '2.5rem',
                  md: '2.5rem',
                  lg: '3rem',
                },
                fontWeight: 'normal',
                lineHeight: 1,
                letterSpacing: 1.5,
                textAlign: { xs: 'left', sm: 'center' },
              }}>
              We created bath water because you deserve a water brand that is a
              good as you are told get first access and earn rewards by sharing
              this link with your friends.
            </Typography>
          </Grid>

          <Grid
            item
            xs={0}
            md={1}
          />
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            my: 1,
          }}>
          <Grid
            item
            xs={0}
            md={1}
          />
          {/* <Grid
            item
            xs={10}
            md={10}
            sx={{
              height: 'auto',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              px: { xs: 2, sm: 3, md: 5 },
            }}>
            <Typography
              sx={{
                color: '#BFD730',
                width: { xs: '100%', sm: '80%', md: '70%' },
                fontFamily: 'Etrusco',
                fontSize: {
                  xs: '1.8rem',
                  sm: '2.5rem',
                  md: '2.5rem',
                  lg: '3rem',
                },
                lineHeight: 1,
                letterSpacing: 1,
                textAlign: { xs: 'center', sm: 'center' },
              }}>
              Share the referral link
            </Typography>
            <Typography
              variant='body1'
              sx={{
                color: '#fff',
                width: { xs: '100%', sm: '80%', md: '70%', lg: '55%' },
                fontFamily: 'Etrusco',
                fontSize: {
                  xs: '1.8rem',
                  sm: '2.5rem',
                  md: '2.5rem',
                  lg: '3rem',
                },
                lineHeight: 1,
                letterSpacing: 1,
                textAlign: { xs: 'center', sm: 'center' },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 'normal',
              }}>
              You can also share your referral link by copying and sending it or
              sharing it on your social media.
            </Typography>
          </Grid> */}

          <Grid
            item
            xs={0}
            md={1}
          />
        </Grid>
        <Grid
          container
          spacing={0}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            // my: 1,
          }}>
          <Grid
            item
            xs={0}
            md={1}
          />
          <Grid
            item
            xs={12}
            md={10}
            sx={{
              height: 'auto',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              px: { xs: 2, sm: 3, md: 5 },
            }}>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                // border: '1px solid white',
                borderRadius: 2,
                px: 1,
                py: { xs: 1 },
              }}>
              <TextField
                placeholder='Enter your email'
                value={`https://drinkbathwater.com/${uniqueId}`}
                inputRef={inputRef}
                sx={{
                  backgroundColor: '#1C96C5',
                  borderRadius: '10px',
                  mb: 0,
                  mr: { xs: 0, sm: 1, md: 1 },
                  width: '100%',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: 'white',
                  },
                  '& .MuiInputBase-input::placeholder': {
                    color: 'white',
                  },
                }}
              />

              <Button
                onClick={copyToClipboard}
                sx={{
                  backgroundColor: '#BFD730',
                  color: '#000',
                  borderRadius: '10px',
                  px: { xs: 4, sm: 5, md: 7 },
                  py: { xs: 1, sm: 1, md: 2 },
                  fontSize: { xs: '0.875rem', sm: '1rem', md: '0.8rem' },
                  fontFamily: 'Etrusco',
                  letterSpacing: 1,
                  fontWeight: 'bold',
                  minWidth: { xs: 'auto', sm: '200px', md: '280px' },
                  width: { xs: '100%', sm: 'auto' },
                  '&:hover': {
                    backgroundColor: '#A0C200',
                  },
                }}>
                Copy Link
              </Button>
            </Box>
            {/* <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Box sx={{ mx: 2 }}>
                <FacebookOutlinedIcon
                  style={{ height: 50, width: 50, color: '#A0C200' }}
                />
              </Box>
              <Box sx={{ mx: 2 }}>
                <XIcon style={{ height: 50, width: 50, color: '#A0C200' }} />
              </Box>
              <Box sx={{ mx: 2 }}>
                <InstagramIcon
                  style={{ height: 50, width: 50, color: '#A0C200' }}
                />
              </Box>
            </Box> */}
          </Grid>

          <Grid
            item
            xs={0}
            md={1}
          />
        </Grid>

        {/* --------------------------------------------------------------------------- */}
        <Box
          // backgroundColor={'#f0f0f0'}

          width={'100%'}>
          <Box
            sx={{
              zIndex: 1,
              // padding: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Typography
              variant='h4'
              fontFamily='Etrusco'
              color='red'
              fontWeight='normal'
              alignItems='center'
              textAlign={{ xs: 'left', sm: 'center', md: 'center' }}
              letterSpacing={2}
              sx={{
                mt: 2,
                mb: 2,
                fontSize: {
                  xs: '1.5rem',
                  sm: '2.5rem',
                  md: '2.5rem',
                  lg: '3rem',
                },
                textAlign: { xs: 'left', sm: 'center', md: 'center' },
              }}>
              You have {days} days left
            </Typography>
            <Typography
              variant='h1'
              sx={{
                fontFamily: 'Etrusco',
                fontWeight: 'bold',
                textAlign: { xs: 'left', sm: 'center', md: 'center' }, // Changed here
                letterSpacing: 1,
                fontSize: { xs: '2.5rem', sm: '3rem', md: '4rem', lg: '5rem' },
              }}>
              {userPoints} Friends joined
            </Typography>
            <Typography
              variant='h4'
              fontFamily='Etrusco'
              fontWeight='normal'
              textAlign={{ xs: 'left', sm: 'center', md: 'center' }} // Changed here
              letterSpacing={2}
              sx={{
                fontSize: {
                  xs: '1.5rem',
                  sm: '2.2rem',
                  md: '2.5rem',
                  lg: '3rem',
                },
              }}>
              1 More For Next Reward: Free Sample Of All Flavours
            </Typography>
          </Box>

          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Grid
              xs={10}
              sm={10}
              md={10}
              lg={10}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}>
              <Box
                sx={{
                  py: { xs: 1, sm: 1, md: 3, lg: 3 },
                  px: { xs: 2, sm: 2, md: 3, lg: 3 },
                  backgroundColor: '#FFA500',
                  borderRadius: 3,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  my: { xs: 1, sm: 1, md: 1, lg: 1 },
                }}>
                <Box
                  sx={{
                    backgroundColor: '#fff',
                    borderRadius: 3,
                    py: { xs: 1 },
                    px: { xs: 2 },
                  }}>
                  <Typography
                    variant='h4'
                    sx={{
                      color: '#000',
                      fontFamily: 'Etrusco',
                      fontWeight: 700,
                      fontSize: {
                        xs: '1rem',
                        sm: '1.25rem',
                        md: '1.5rem',
                        lg: '1.75rem',
                      },
                    }}>
                    #1 Referrer
                  </Typography>
                </Box>
                <Typography
                  variant='h4'
                  sx={{
                    fontFamily: 'Etrusco',
                    fontWeight: 700,
                    fontSize: {
                      xs: '1rem',
                      sm: '1.25rem',
                      md: '1.5rem',
                      lg: '1.75rem',
                    },
                  }}>
                  2024 Olympics Trip
                </Typography>
              </Box>

              <Box
                sx={{
                  py: { xs: 1, sm: 1, md: 3, lg: 3 },
                  px: { xs: 2, sm: 2, md: 3, lg: 3 },
                  backgroundColor: '#FFA500',
                  borderRadius: 3,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  my: { xs: 1, sm: 1, md: 1, lg: 1 },
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Box
                    sx={{
                      backgroundColor: '#fff',
                      borderRadius: 3,
                      py: { xs: 1 },
                      px: { xs: 2 },
                    }}>
                    <Typography
                      variant='h4'
                      sx={{
                        color: '#000',
                        fontFamily: 'Etrusco',
                        fontWeight: 700,
                        fontSize: {
                          xs: '1rem',
                          sm: '1.25rem',
                          md: '1.5rem',
                          lg: '1.75rem',
                        },
                      }}>
                      Top Ten Referrer
                    </Typography>
                  </Box>
                  <Typography
                    variant='h4'
                    sx={{
                      color: '#000',
                      fontFamily: 'Etrusco',
                      mx: { xs: 1, sm: 1, md: 1, lg: 1 },
                      letterSpacing: 1,
                      fontSize: {
                        xs: '1rem',
                        sm: '1.25rem',
                        md: '1.5rem',
                        lg: '1.75rem',
                      },
                    }}>
                    (20 Friends)
                  </Typography>
                </Box>
                <Typography
                  variant='h4'
                  sx={{
                    fontFamily: 'Etrusco',
                    fontWeight: 700,
                    fontSize: {
                      xs: '1rem',
                      sm: '1.25rem',
                      md: '1.5rem',
                      lg: '1.75rem',
                    },
                  }}>
                  One Year Of Free Bath Water
                </Typography>
              </Box>

              <Box
                sx={{
                  py: { xs: 1, sm: 1, md: 3, lg: 3 },
                  px: { xs: 2, sm: 2, md: 3, lg: 3 },
                  backgroundColor: '#FFA500',
                  borderRadius: 3,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  my: { xs: 1, sm: 1, md: 1, lg: 1 },
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Box
                    sx={{
                      height: 15,
                      width: 15,
                      border: userPoints == 0 && '2px solid #ED3B24',
                      backgroundColor: userPoints >= 1 && '#ED3B24',
                      borderRadius: 5,
                    }}
                  />
                  <Typography
                    variant='h4'
                    sx={{
                      color: '#000',
                      fontFamily: 'Etrusco',
                      letterSpacing: 1,
                      mx: { xs: 1, sm: 1, md: 2, lg: 2 },
                      fontSize: {
                        xs: '1rem',
                        sm: '1.25rem',
                        md: '1.5rem',
                        lg: '1.75rem',
                      },
                    }}>
                    1 Friend
                  </Typography>
                </Box>
                <Typography
                  variant='h4'
                  sx={{
                    fontFamily: 'Etrusco',
                    fontWeight: 700,
                    fontSize: {
                      xs: '1rem',
                      sm: '1.25rem',
                      md: '1.5rem',
                      lg: '1.75rem',
                    },
                  }}>
                  Free Sample Of All Flavours
                </Typography>
              </Box>

              <Box
                sx={{
                  py: { xs: 1, sm: 1, md: 3, lg: 3 },
                  px: { xs: 2, sm: 2, md: 3, lg: 3 },
                  backgroundColor: '#FFA500',
                  borderRadius: 3,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  my: { xs: 1, sm: 1, md: 1, lg: 1 },
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Box
                    sx={{
                      height: 15,
                      width: 15,
                      border: userPoints < 5 && '2px solid #ED3B24',
                      backgroundColor: userPoints >= 5 && '#ED3B24',
                      borderRadius: 5,
                    }}
                  />
                  <Typography
                    variant='h4'
                    sx={{
                      color: '#000',
                      fontFamily: 'Etrusco',
                      letterSpacing: 1,
                      mx: { xs: 1, sm: 1, md: 2, lg: 2 },
                      fontSize: {
                        xs: '1rem',
                        sm: '1.25rem',
                        md: '1.5rem',
                        lg: '1.75rem',
                      },
                    }}>
                    5 Friends
                  </Typography>
                </Box>
                <Typography
                  variant='h4'
                  sx={{
                    fontFamily: 'Etrusco',
                    fontWeight: 700,
                    fontSize: {
                      xs: '1rem',
                      sm: '1.25rem',
                      md: '1.5rem',
                      lg: '1.75rem',
                    },
                  }}>
                  Bath Water Branded Merch
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              backgroundColor: '#00AEEF',
              py: 4,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 6,
            }}>
            <Typography
              variant='h5'
              color={'#fff'}
              fontFamily={'Etrusco'}
              fontWeight={'bold'}
              sx={{
                fontSize: {
                  xs: '1.25rem',
                  sm: '1.5rem',
                  md: '1.75rem',
                  lg: '2rem',
                },
              }}>
              Need Help? Contact us at : info@bathwater.com
            </Typography>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
// jk
export default ReferalPage;
